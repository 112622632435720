import React from 'react'
import TabrificsImage from '../../assets/TAB_SKY_NEW.png'
import ScottishDisclosuresImage from '../../assets/ScottishDisclosures.png'
import DashOwlImage from '../../assets/DashOwlBrowser.png'
export default function Projects_old() {
  return (
    <>
    <div>
        <a href="https://tabrifics.com" target="_blank" style={{color:"black"}}>
            <strong>Tabrifics</strong> 
        </a>
        <p>Small-batch treat company selling Scottish Tablet handmade in Scotland with a decades old family recipe.</p>
        <ul>
            <li><a href="https://tabrifics.com/pages/stockists">Stockist Map</a> with Google Sheets as database</li>
            <li>Customised Shopify Theme</li>
        </ul>
        <div className="my-2">
            <span class="badge bg-light text-dark">Shopify</span>
        </div>
    </div>
    <br />
    <div>
        <a href="https://scottish-disclosures.co.uk" target="_blank" style={{color:"black"}}>
            <strong>Scottish Disclosures</strong>
        </a>
        <p>Umbrella body registered with Disclosure Scotland that processes PVG, Basic, Standad & Enhanced Disclosure Scotland checks on behalf of other businesses.</p>
        <ul>
            <li>Business Website with Blog & Analytics Tracking</li>
            <li>Internal Tool For Business Processes</li>
        </ul>
        <div className="my-2">
            <span class="badge bg-light text-dark">Django</span>
        </div>
    </div>

    <br />

    <div>
        <a href="https://dashowl.co.uk" target="_blank" style={{color:"black"}}>
            <strong>DashOwl</strong>
        </a>
        <p>Formula 24 telemetry dashboard.</p>
        <ul>
            <li>Real-time telemetry dashboard for Formula 24 racing teams</li>
            <li><a href="https://vis.dashowl.co.uk/">Logfile visualiser</a> for eChook telemetry data</li>
        </ul>
        <div className="my-2">
            
            <span class="badge bg-light text-dark">React</span>
            <span class="badge bg-light text-dark">Firebase</span>
        </div>
    </div>
    <br />
    <div width="100%" height="150px" className="your-project-box">
        <a href="mailto:alanjsfleming@gmail.com" style={{color:"black"}}>
            <strong>Your Project?</strong>
        </a>
        <p>Send me an email and we can talk about how we can work together!</p>

    </div>
    </>
  )
}
